<ngx-spinner
    name="managementSpinner"
    color="#fff"
    size="medium"
    type="ball-clip-rotate-multiple">
    <p style="font-size: 20px; color: white">Uploading media...</p></ngx-spinner>

<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent"
     @zoomIn>

    <!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
        <!-- Title -->

        <div
            class="text-4xl font-extrabold tracking-tight w-full lg:w-1/4">{{ destination.ds_title }} {{ reviewMode ? " - In Review" : "" }}
        </div>

        <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4" *ngIf="!reviewMode">
            <!-- Preview -->
            <button
                *ngIf="adminMode"
                class="ml-4"
                mat-stroked-button
                (click)="sendCommand(null)">
                Back
            </button>
            <!-- Preview -->
            <button
                *ngIf="destination.en_state === 4"
                class="ml-4"
                mat-stroked-button
                (click)="togglePublish()">
                Unpublish
            </button>
            <!-- Preview -->
            <button
                class="ml-4"
                mat-stroked-button
                [color]="'warn'"
                (click)="saveAndClose(true)">
                Preview
            </button>
            <!-- Save -->
            <button
                [disabled]="disableSave"
                class="ml-4"
                mat-stroked-button
                [color]="'primary'"
                (click)="sendToReview()">
                Send to Review
            </button>
        </div>

        <div class="lg:flex mt-6 sm:mt-0 sm:ml-4 w-full" *ngIf="reviewMode">

            <mat-form-field class="w-full pr-2"
                            [subscriptSizing]="'dynamic'">
                <input
                    placeholder="Review Comment"
                    (input)="onInputMaxReach($event, 250)"
                    maxlength="250"
                    matInput
                    [required]="true"
                    [formControl]="commentInputControl">
            </mat-form-field>
            <div class="lg:flex w-full lg:w-1/4 mt-4 lg:mt-1">
                <!-- Preview -->
                <button
                    class="ml-2 w-1/5 lg:w1/3"
                    mat-stroked-button
                    (click)="cancelReview()">
                    Back
                </button>
                <!-- Preview -->
                <button
                    class="ml-2 w-1/5 lg:w1/3"
                    mat-stroked-button
                    (click)="onNavigate()">
                    Preview
                </button>
                <!-- Preview -->
                <button
                    [disabled]="commentInputControl.invalid"
                    class="ml-2 w-1/4 lg:w-1/2"
                    mat-stroked-button
                    [color]="'warn'"
                    (click)="reviewAction('reject')">
                    Reject
                </button>
                <!-- Preview -->
                <button
                    class="ml-2 w-1/4 lg:w-1/2"
                    mat-stroked-button
                    [color]="'primary'"
                    (click)="reviewAction('approve')">
                    Approve
                </button>
            </div>
        </div>
    </div>

    <mat-tab-group class="pt-2 md:px-2 full-height-tabs" [animationDuration]="'0ms'"
                   (selectedTabChange)="loadMap($event)">

        <mat-tab label="Home">
            <div class="pb-26 pr-4 max-h-fit">

                <!-- Name -->
                <mat-label class="flex"><p>Name</p>
                    <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-1"
                              matTooltip="This is your property's identifier in the admin portal."></mat-icon>
                </mat-label>
                <mat-form-field class="w-full lg:w-2/3 pr-2">
                    <input
                        [readonly]="reviewMode"
                        (input)="onInputMaxReach($event, 250)"
                        maxlength="250"
                        matInput
                        (keyup)="onKeyupAutosave()"
                        [(ngModel)]="destination.ds_name">
                </mat-form-field>

                <!-- ID -->
                <ng-container *ngIf="destination.id_destination && adminMode">
                    <mat-label class="flex"><p>ID</p>
                        <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-1"
                                  matTooltip="This is your property's identifier in the admin portal. For support use this ID."></mat-icon>
                    </mat-label>
                    <mat-form-field class="w-full lg:w-2/3 pr-2">
                        <input
                            class="text-yellow-600"
                            readonly
                            maxlength="250"
                            matInput
                            [(ngModel)]="destination.id_destination">
                    </mat-form-field>
                </ng-container>

                <!-- Title -->
                <ng-container *ngIf="adminMode">
                    <mat-label class="flex"><p>Title</p>
                        <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-1"
                                  matTooltip="This is your property's name on the member portal."></mat-icon>
                    </mat-label>
                    <mat-form-field class="w-full lg:w-2/3 pr-2">
                        <input
                            [readonly]="reviewMode"
                            (input)="onInputMaxReach($event, 150)"
                            maxlength="150"
                            matInput
                            (keyup)="onKeyupAutosave()"
                            [(ngModel)]="destination.ds_title">
                    </mat-form-field>
                </ng-container>

                <!-- Destination Type -->
                <!--                <mat-label class="flex"><p>Destination Type</p>-->
                <!--                    <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-1"-->
                <!--                              matTooltip="This is your property's identifier in the admin portal."></mat-icon>-->
                <!--                </mat-label>-->
                <ng-container *ngIf="adminMode">
                    <mat-form-field class="w-full lg:w-2/3 pr-2">
                        <mat-label>Destination Type</mat-label>
                        <input
                            class="text-yellow-600"
                            [readonly]="true"
                            matInput
                            [(ngModel)]="destination.ds_destinationtype">
                    </mat-form-field>

                    <div class="w-full lg:w-2/3 pr-2">
                        <!-- Great Grandparent Name -->
                        <mat-form-field class="w-full lg:w-1/3 pr-2" *ngIf="destination?.ggparent">
                            <mat-label>{{ destination?.ggparent?.ds_destinationtype }}</mat-label>
                            <!--                            <mat-label class="flex"><p>Continent</p>-->
                            <!--                                <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-1"-->
                            <!--                                          matTooltip="This is your property's identifier in the admin portal."></mat-icon>-->
                            <!--                            </mat-label>-->
                            <input
                                class="text-yellow-600"
                                [readonly]="true"
                                matInput
                                [value]="destination?.ggparent?.ds_name">
                        </mat-form-field>

                        <!-- Great Grandparent Name -->
                        <mat-form-field class="w-full lg:w-1/3 pr-2" *ngIf="destination?.gparent">
                            <mat-label>{{ destination?.gparent?.ds_destinationtype }}</mat-label>
                            <!--                            <mat-label class="flex"><p>Country</p>-->
                            <!--                                <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-1"-->
                            <!--                                          matTooltip="This is your property's identifier in the admin portal."></mat-icon>-->
                            <!--                            </mat-label>-->
                            <input
                                class="text-yellow-600"
                                [readonly]="true"
                                matInput
                                [value]="destination?.gparent?.ds_name">
                        </mat-form-field>

                        <mat-form-field class="w-full lg:w-1/3 pr-2">
                            <mat-label class="flex">
                                <p>{{ destination?.parent?.ds_destinationtype ? destination?.parent?.ds_destinationtype : "Parent" }}</p>
                                <mat-icon svgIcon="heroicons_outline:exclamation-circle"
                                          class="pb-1"
                                          matTooltip="Search for the destination's parent.
                                       If the parent is empty or incorrect, it will revert to the previous parent.
                                        Only a correct parent will reflect changes in the UI."></mat-icon>
                            </mat-label>
                            <input type="text"
                                   [readonly]="!adminMode || reviewMode"
                                   #autoCompleteTrigger="matAutocompleteTrigger"
                                   placeholder="Parent"
                                   aria-label="Parent"
                                   (blur)="onBlur()"
                                   (keyup)="onKeyupAutoSearch($event)"
                                   matInput [formControl]="parentControl"
                                   [matAutocomplete]="auto">
                            <mat-autocomplete
                                requireSelection="true"
                                (optionSelected)="countryClick($event)"
                                #auto="matAutocomplete"
                                [displayWith]="displayFn"
                                autoActiveFirstOption="true">
                                <mat-option *ngFor="let city of filteredParents | async" [value]="city">
                                    {{ city.ds_name }}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error
                                *ngIf="parentCheckerRequired && parentControl.invalid && (parentControl.touched || parentControl.dirty)">
                                Please select a valid parent. If not, it will revert to the previous parent on reload.
                            </mat-error>
                        </mat-form-field>
                    </div>

                </ng-container>

                <div class="w-full max-h-fit lg:w-2/3">
                    <!-- Description -->
                    <mat-label class="flex"><p>Description</p>
                        <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-1"
                                  matTooltip="This text appears in the body of the member portal."></mat-icon>
                    </mat-label>
                    <!-- Body -->
                    <!--                        (onContentChanged)="quillAutosave(destination, 'tx_description', $event)"-->
                    <quill-editor
                        (onEditorCreated)="onEditorCreated($event)"
                        [readOnly]="reviewMode"
                        (onContentChanged)="onKeyupAutosave()"
                        class="mt-2 w-full custom-quill"
                        [(ngModel)]="destination.tx_description"
                        [bounds]="'self'"
                        [modules]="quillModules" [formats]="quillFormats"></quill-editor>
                </div>

                <div class="w-full max-h-fit lg:w-2/3" *ngIf="destination.ds_datalabel1">
                    <!-- Description -->
                    <mat-label class="flex"><p>{{ destination.ds_datalabel1 }}</p>
                        <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-1"
                                  matTooltip="This text appears in the body of the member portal."></mat-icon>
                    </mat-label>
                    <!-- Body -->
                    <quill-editor
                        (onEditorCreated)="onEditorCreated($event)"
                        [readOnly]="reviewMode"
                        (onContentChanged)="onKeyupAutosave()"
                        class="mt-2 w-full custom-quill"
                        [(ngModel)]="destination.tx_data1"
                        [bounds]="'self'"
                        [modules]="quillModules" [formats]="quillFormats"></quill-editor>
                </div>

                <div class="w-full max-h-fit lg:w-2/3" *ngIf="destination.ds_datalabel2">
                    <!-- Description -->
                    <mat-label class="flex"><p>{{ destination.ds_datalabel2 }}</p>
                        <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-1"
                                  matTooltip="This text appears in the body of the member portal."></mat-icon>
                    </mat-label>
                    <!-- Body -->
                    <quill-editor
                        (onEditorCreated)="onEditorCreated($event)"
                        [readOnly]="reviewMode"
                        (onContentChanged)="onKeyupAutosave()"
                        class="mt-2 w-full custom-quill"
                        [(ngModel)]="destination.tx_data2"
                        [bounds]="'self'"
                        [modules]="quillModules" [formats]="quillFormats"></quill-editor>
                </div>

                <div class="w-full max-h-fit lg:w-2/3" *ngIf="destination.ds_datalabel3">
                    <!-- Description -->
                    <mat-label class="flex"><p>{{ destination.ds_datalabel3 }}</p>
                        <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-1"
                                  matTooltip="This text appears in the body of the member portal."></mat-icon>
                    </mat-label>
                    <!-- Body -->
                    <quill-editor
                        (onEditorCreated)="onEditorCreated($event)"
                        [readOnly]="reviewMode"
                        (onContentChanged)="onKeyupAutosave()"
                        class="mt-2 w-full custom-quill"
                        [(ngModel)]="destination.tx_data3"
                        [bounds]="'self'"
                        [modules]="quillModules" [formats]="quillFormats"></quill-editor>
                </div>

            </div>
        </mat-tab>

        <mat-tab label="Features">
            <div class="pb-20 pr-4 flex flex-col flex-auto">

                <ng-container *ngFor="let item of destination.ar_props; index as i">

                    <div class="flex-auto">

                        <mat-label class="flex"><p
                            class="text-2xl font-bold pr-2">{{ item.ds_label_destinationprop }}</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class=""
                                      matTooltip="Select options that describe your destination in this category.
                                      Min: {{item.vi_min_destinationpropdtype}}
                                      Max: {{item.vi_max_destinationpropdtype}}"></mat-icon>
                        </mat-label>

                        <!-- Multiple Select -->
                        <mat-chip-listbox [multiple]="true" class="pt-2"
                                          [disabled]="reviewMode"
                                          *ngIf="!(item.vi_max_destinationpropdtype===1 && item.vi_min_destinationpropdtype===1)">
                            <mat-chip-option
                                *ngFor="let prop of item.ar_items"
                                (click)="featuresAutosave(prop)"
                                [disabled]="
                                (countSelectedProps(item) >= item.vi_max_destinationpropdtype && !prop.bl_Active) &&
                                (countSelectedProps(item) <= item.vi_min_destinationpropdtype && !prop.bl_Active)"
                                [selected]="prop.bl_Active">
                                {{ prop.ds_label_destinationpropoption }}
                            </mat-chip-option>
                        </mat-chip-listbox>

                        <!-- Single Select -->
                        <mat-chip-listbox class="pt-2"
                                          [disabled]="reviewMode"
                                          *ngIf="(item.vi_max_destinationpropdtype===1) && (item.vi_min_destinationpropdtype===1)">
                            <mat-chip-option
                                *ngFor="let prop of item.ar_items"
                                [selectable]="!prop.bl_Active"
                                (click)="featuresAutosaveUnique(item.ar_items, prop)"
                                [selected]="prop.bl_Active">
                                {{ prop.ds_label_destinationpropoption }}
                            </mat-chip-option>
                        </mat-chip-listbox>

                        <!-- Alert -->
                        <fuse-alert
                            class="mt-8"
                            *ngIf="alertToggle(item)"
                            [appearance]="'outline'"
                            [showIcon]="false"
                            [type]="'error'">
                            {{ 'it requires at least ' + item.vi_min_destinationpropdtype + ' and at most ' + item.vi_max_destinationpropdtype + ' ' + item.ds_label_destinationprop + ' to be selected' }}
                        </fuse-alert>

                    </div>

                    <hr>
                </ng-container>

            </div>
        </mat-tab>

        <mat-tab label="Map">
            <ng-template matTabContent>
                <div class="pb-32 pr-4">
                    <div class="flex-auto">
                        <!-- Official Name -->
                        <mat-form-field class="w-full pr-2" *ngIf="!reviewMode">
                            <mat-label class="flex"><p class="pr-2">Search Google Address</p>
                                <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                          matTooltip="Search with a direction your destination in google"></mat-icon>
                            </mat-label>
                            <input
                                class="pt-2"
                                matInput
                                [(ngModel)]="googleAddressSearch"
                                (keydown.enter)="verifyGoogleAddress()">

                            <!-- Verify -->
                            <button
                                class=""
                                mat-flat-button
                                [color]="'primary'"
                                (click)="verifyGoogleAddress()">
                                Verify
                            </button>
                        </mat-form-field>
                    </div>

                    <div class="flex-auto">

                        <!-- Official Name -->
                        <mat-form-field class="w-full lg:w-1/2 pr-2">
                            <mat-label>Google Address</mat-label>
                            <input
                                class="text-yellow-600"
                                readonly
                                matInput
                                [(ngModel)]="destination.ds_googleaddress">
                        </mat-form-field>

                        <!-- Google Match -->
                        <mat-form-field class="w-full lg:w-1/2 pr-2">
                            <mat-label>Google Suggested</mat-label>
                            <input
                                class="text-yellow-600"
                                readonly
                                matInput
                                [(ngModel)]="destination.ds_googlematch">
                            <button
                                *ngIf="!reviewMode"
                                class="justify-center lg:justify-end"
                                mat-flat-button
                                [color]="'primary'"
                                (click)="saveAndClose()">
                                Save new address
                            </button>
                        </mat-form-field>
                    </div>

                    <div class="flex flex-col sm:flex-row">
                        <div class="w-full pb-2 lg:w-1/2 pr-2">
                            <mat-label class="flex"><p class="text-2xl font-bold pr-2">Map</p>
                                <mat-icon svgIcon="heroicons_outline:exclamation-circle" class=""
                                          matTooltip="You can choose with more precision the pin in the map."></mat-icon>
                            </mat-label>

                            <google-map
                                #googleMap
                                mapId="mapGoogle"
                                *ngIf="loadMapVar"
                                height="500px"
                                width="100%"
                                (mapClick)="clickGoogleMap($event)"
                                [center]="mapCenter"
                                [zoom]="8"
                            >
                                <map-advanced-marker
                                    *ngFor="let marker of markersList"
                                    [title]="marker.title"
                                    [content]="marker.content"
                                    [gmpDraggable]="marker.gmpDraggable"
                                    [draggable]="marker.draggable"
                                    [position]="{ lat: marker.position.lat, lng: marker.position.lng }"
                                    (mapDragend)="onMarkerDragEnd($event, marker)"
                                />
                            </google-map>
                        </div>
                        <div class="w-full lg:w-1/2 pr-2">
                            <mat-label class="flex"><p class="text-2xl font-bold pr-2">Coordinates</p>
                                <mat-icon svgIcon="heroicons_outline:exclamation-circle" class=""
                                          matTooltip="You can also use your destination coordinates to set the pin."></mat-icon>
                            </mat-label>
                            <ng-container>
                                <!-- Latitude -->
                                <mat-form-field class="w-full lg:w-2/3" [subscriptSizing]="'dynamic'">
                                    <mat-label>Latitude P: Central</mat-label>
                                    <input
                                        [readonly]="reviewMode"
                                        matInput
                                        [(ngModel)]="destination.vr_lat"
                                        (keyup)="onKeyup($event, destination.vr_lat, centralMarker, 'vr_lat', true)">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="'maps_ugc'"></mat-icon>
                                </mat-form-field>
                                <!-- Longitude -->
                                <mat-form-field class="w-full lg:w-2/3" [subscriptSizing]="'dynamic'">
                                    <mat-label>Longitude P: Central</mat-label>
                                    <input
                                        [readonly]="reviewMode"
                                        matInput
                                        [(ngModel)]="destination.vr_long"
                                        (keyup)="onKeyup($event, destination.vr_long, centralMarker, 'vr_long', false)">
                                    <button
                                        mat-icon-button
                                        matSuffix>
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'maps_ugc'"></mat-icon>
                                    </button>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="destination.bl_locationboundaries">
                                <!-- Latitude -->
                                <mat-form-field class="w-full lg:w-1/2 pr-2" [subscriptSizing]="'dynamic'" *ngIf="true">
                                    <mat-label>Latitude 1: Northwest</mat-label>
                                    <input
                                        [readonly]="reviewMode"
                                        matInput
                                        (keyup)="onKeyup($event, destination.vr_northwestlat, firstMarker, 'vr_northwestlat', true)"
                                        [(ngModel)]="destination.vr_northwestlat">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="'maps_ugc'"></mat-icon>
                                </mat-form-field>
                                <!-- Longitude -->
                                <mat-form-field class="w-full lg:w-1/2" [subscriptSizing]="'dynamic'" *ngIf="true">
                                    <mat-label>Longitude 1: Northwest</mat-label>
                                    <input
                                        [readonly]="reviewMode"
                                        matInput
                                        (keyup)="onKeyup($event, destination.vr_northwestlong, firstMarker, 'vr_northwestlong', false)"
                                        [(ngModel)]="destination.vr_northwestlong">
                                    <button
                                        mat-icon-button
                                        matSuffix>
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'maps_ugc'"></mat-icon>
                                    </button>
                                </mat-form-field>

                                <!-- Latitude -->
                                <mat-form-field class="w-full lg:w-1/2 pr-2" [subscriptSizing]="'dynamic'" *ngIf="true">
                                    <mat-label>Latitude 2: Northeast</mat-label>
                                    <input
                                        [readonly]="reviewMode"
                                        matInput
                                        (keyup)="onKeyup($event, destination.vr_northeasttlat, secondMarker, 'vr_northeasttlat', true)"
                                        [(ngModel)]="destination.vr_northeasttlat">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="'maps_ugc'"></mat-icon>
                                </mat-form-field>
                                <!-- Longitude -->
                                <mat-form-field class="w-full lg:w-1/2" [subscriptSizing]="'dynamic'" *ngIf="true">
                                    <mat-label>Longitude 2: Northeast</mat-label>
                                    <input
                                        [readonly]="reviewMode"
                                        matInput
                                        (keyup)="onKeyup($event, destination.vr_northeastlong, secondMarker, 'vr_northeastlong', false)"
                                        [(ngModel)]="destination.vr_northeastlong">
                                    <button
                                        mat-icon-button
                                        matSuffix>
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'maps_ugc'"></mat-icon>
                                    </button>
                                </mat-form-field>

                                <!-- Latitude -->
                                <mat-form-field class="w-full lg:w-1/2 pr-2" [subscriptSizing]="'dynamic'" *ngIf="true">
                                    <mat-label>Latitude 3: Southwest</mat-label>
                                    <input
                                        [readonly]="reviewMode"
                                        matInput
                                        (keyup)="onKeyup($event, destination.vr_southwestlat, thirdMarker, 'vr_southwestlat', true)"
                                        [(ngModel)]="destination.vr_southwestlat">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="'maps_ugc'"></mat-icon>
                                </mat-form-field>
                                <!-- Longitude -->
                                <mat-form-field class="w-full lg:w-1/2" [subscriptSizing]="'dynamic'" *ngIf="true">
                                    <mat-label>Longitude 3: Southwest</mat-label>
                                    <input
                                        [readonly]="reviewMode"
                                        matInput
                                        (keyup)="onKeyup($event, destination.vr_southwestlong, thirdMarker, 'vr_southwestlong', false)"
                                        [(ngModel)]="destination.vr_southwestlong">
                                    <button
                                        mat-icon-button
                                        matSuffix>
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'maps_ugc'"></mat-icon>
                                    </button>
                                </mat-form-field>

                                <!-- Latitude -->
                                <mat-form-field class="w-full lg:w-1/2 pr-2" [subscriptSizing]="'dynamic'" *ngIf="true">
                                    <mat-label>Latitude 4: Southeast</mat-label>
                                    <input
                                        [readonly]="reviewMode"
                                        matInput
                                        (keyup)="onKeyup($event, destination.vr_southeastlat, fourthMarker, 'vr_southeastlat', true)"
                                        [(ngModel)]="destination.vr_southeastlat">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="'maps_ugc'"></mat-icon>
                                </mat-form-field>
                                <!-- Longitude -->
                                <mat-form-field class="w-full lg:w-1/2" [subscriptSizing]="'dynamic'" *ngIf="true">
                                    <mat-label>Longitude 4: Southeast</mat-label>
                                    <input
                                        [readonly]="reviewMode"
                                        matInput
                                        (keyup)="onKeyup($event, destination.vr_southeastlong, fourthMarker, 'vr_southeastlong', false)"
                                        [(ngModel)]="destination.vr_southeastlong">
                                    <button
                                        mat-icon-button
                                        matSuffix>
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'maps_ugc'"></mat-icon>
                                    </button>
                                </mat-form-field>
                            </ng-container>
                        </div>
                    </div>
                </div>


            </ng-template>
        </mat-tab>

        <mat-tab label="Gallery">
            <ng-template matTabContent>
                <div class="tab-content mx-auto max-w-400 p-6 text-center pb-48">
                    <!-- Gallery -->
                    <div class="w-full">
                        <mat-label class="mb-5 text-left">
                            <p class="text-4xl font-extrabold">Gallery
                                <mat-icon svgIcon="heroicons_outline:exclamation-circle"
                                          matTooltip="These images are displayed in the view more photos. Click for more info!"
                                          (click)="moreInfoTooltip(
                                      'The two images will randomly displayed along side the banner.',
                                      'If you add more images, it will be displayed clicking view more photos',
                                      './assets/images/help/gallery-help.png')"></mat-icon>

                                <button
                                    *ngIf="!reviewMode"
                                    class="ml-auto sm:ml-0 inline-flex items-center px-3 py-2 bg-gray-800 text-white rounded shadow hover:bg-gray-700 text-md font-normal lg:hidden"
                                    style="float: right"
                                    [matMenuTriggerFor]="menu">
                                    <span>Options</span>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button
                                        *ngIf="!reviewMode"
                                        (click)="onFileInputClick('Gallery')"
                                        mat-menu-item>
                                        <mat-icon>file_upload</mat-icon>
                                        <span>Upload</span>
                                    </button>
                                    <button
                                        *ngIf="!reviewMode"
                                        [disabled]="destination.ar_gallery.length === 0"
                                        (click)="removeGalleryImage(index)"
                                        mat-menu-item>
                                        <mat-icon>delete</mat-icon>
                                        <span>Delete</span>
                                    </button>
                                    <button
                                        *ngIf="!reviewMode"
                                        [disabled]="destination.ar_gallery.length === 0"
                                        (click)="setImageToBanner(index, 'main')"
                                        mat-menu-item>
                                        <mat-icon>swap_horiz</mat-icon>
                                        <span>Set Main Banner</span>
                                    </button>
                                    <button
                                        *ngIf="!reviewMode"
                                        [disabled]="destination.ar_gallery.length === 0"
                                        (click)="setImageToBanner(index, 'card')"
                                        mat-menu-item>
                                        <mat-icon>swap_horiz</mat-icon>
                                        <span>Set Card Display</span>
                                    </button>
                                </mat-menu>

                                <!-- Fetch selected filed on change -->
                                <input hidden type="file"
                                       [id]="'FileUploadGallery'"
                                       (change)="addGalleryElement($event)"
                                       name="fileUpload"
                                       multiple="multiple" accept="image/*"/>
                            </p>

                        </mat-label>
                        <gallery
                            #galleryRef
                            class="bg-transparent mt-2"
                            *ngIf="loadGallery"
                            loadingStrategy="preload"
                            (indexChange)="index = $event.currIndex"
                            [items]="galleryImages"
                            [counter]="destination.ar_gallery.length > 0"
                            thumbHeight="120"
                            thumbWidth="120"
                            thumbPosition="top">

                        </gallery>
                        <div class="space-x-2 pt-4 hidden lg:flex items-center justify-center">
                            <button
                                *ngIf="!reviewMode"
                                (click)="onFileInputClick('Gallery')"
                                class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center justify-center space-x-2">
                                <mat-icon class="text-white">file_upload</mat-icon>
                                <span>Upload New Image</span>
                            </button>
                            <button
                                *ngIf="!reviewMode"
                                [disabled]="destination.ar_gallery.length === 0"
                                (click)="removeGalleryImage(index)"
                                class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center justify-center space-x-2">
                                <mat-icon class="text-white">delete</mat-icon>
                                <span>Delete Current Image</span>
                            </button>
                            <button
                                *ngIf="!reviewMode"
                                [disabled]="destination.ar_gallery.length === 0"
                                (click)="setImageToBanner(index, 'main')"
                                class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center justify-center space-x-2">
                                <mat-icon class="text-white">swap_horiz</mat-icon>
                                <span>Set Current Image as Main Banner</span>
                            </button>
                            <button
                                *ngIf="!reviewMode"
                                [disabled]="destination.ar_gallery.length === 0"
                                (click)="setImageToBanner(index, 'card')"
                                class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center justify-center space-x-2">
                                <mat-icon class="text-white">swap_horiz</mat-icon>
                                <span>Set Current Image as Card Display</span>
                            </button>
                        </div>

                    </div>

                    <hr>

                    <!-- Banner Section -->
                    <div class="lg:flex items-center justify-center lg:space-x-8 lg:p-6">
                        <div class="w-full lg:w-1/3">
                            <img [src]="destination.im_media"
                                 default="https://media.myaltituderewards.com/content/undefined"
                                 alt="Banner Placeholder" class="rounded shadow">
                        </div>

                        <!-- Text and Upload Section -->
                        <div class="w-full lg:w-2/3 lg:text-left">
                            <h2 class="text-4xl font-semibold mb-2 text-primary-600">Banner Image</h2>
                            <p class="mb-4">The main banner is the large, prominent image displayed on your business
                                profile.
                                It serves as the first impression for visitors and highlights your brand or business
                                offerings.
                                You can use this space to showcase your most impactful photo, whether it’s your
                                storefront, services, or a key product.</p>
                            <p class="pointer-events-auto"><strong class="text-indigo-400"
                                                                   (click)="moreInfoTooltip('','','/assets/images/help/banner-help.png')">Click
                                here to see its location</strong></p>
                            <h2 class="text-4xl font-semibold mb-2 text-primary-600">Guidelines for Changing the
                                Image:</h2>
                            <p class="mb-4"><strong>Purpose:</strong> Select an image that best represents your business
                                or creates a strong visual impact.</p>
                            <p class="mb-4"><strong>Quality:</strong> Ensure the image is high-resolution and properly
                                cropped for the best display.</p>
                            <p class="mb-4"><strong>Relevance:</strong> Use a current and engaging image that aligns
                                with your business goals (e.g., promotions, seasonal themes, or core services).</p>
                            <p class="mb-4"><strong>Note:</strong> if you have an video added, the video will be
                                displayed instead of the banner.</p>
                            <p *ngIf="destination.im_mediavideo && !destination.im_mediavideo.endsWith('content/undefined')"
                               class="text-yellow-500"><strong>Warning:</strong> A video exists. This banner will not be
                                displayed.</p>
                            <button
                                *ngIf="!reviewMode"
                                (click)="MainImageFileInput.click()"
                                class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 lg:my-float-right">
                                Change Photo
                            </button>
                            <input hidden type="file"
                                   #MainImageFileInput
                                   (change)="onChangeFile($event, 'im_media', 'main', null)"
                                   name="fileUpload" accept="image/*"/>
                        </div>
                    </div>

                    <hr>

                    <!-- Card Section -->
                    <div class="lg:flex items-center justify-center lg:space-x-8 lg:p-6">
                        <!-- Image Section -->
                        <div class="w-full lg:w-1/3">
                            <img [src]="destination.im_mediacard"
                                 default="https://media.myaltituderewards.com/content/undefined"
                                 alt="Banner Placeholder" class="rounded shadow">
                        </div>

                        <!-- Text and Upload Section -->
                        <div class="w-full lg:w-2/3 lg:text-left">
                            <h2 class="text-4xl font-semibold mb-2 text-primary-600">Card Image</h2>
                            <p class="mb-4">This image represents your business in promotional carousels and grid
                                displays across the platform, where it will appear alongside other businesses.
                                It’s your opportunity to stand out and capture attention, encouraging users to click and
                                explore your profile.</p>
                            <p><strong class="text-indigo-400"
                                       (click)="moreInfoTooltip('','','/assets/images/help/card-help.png')">Click here
                                to see its location</strong></p>
                            <h2 class="text-4xl font-semibold mb-2 text-primary-600">Guidelines for Choosing an
                                Effective Image:</h2>
                            <p class="mb-4"><strong>Stand Out:</strong> Select an eye-catching image that helps your
                                business stand out among competitors. Highlight what makes your business unique—whether
                                it’s a signature product, a vibrant storefront, or an engaging service moment.</p>
                            <p class="mb-4"><strong>High Quality:</strong> Use a high-resolution image with clear
                                details and bright, inviting visuals to make a strong impression in smaller formats like
                                carousels or grids.</p>
                            <p class="mb-4"><strong>Focus on Impact:</strong> Choose visuals that are immediately
                                striking and memorable to attract clicks over competing businesses.</p>
                            <p class="mb-4"><strong>Relevance:</strong> Ensure the image represents your brand and key
                                offerings effectively, giving users a reason to explore your profile.</p>
                            <button
                                *ngIf="!reviewMode"
                                (click)="ImageCardFileInput.click()"
                                class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 lg:my-float-right">
                                Change Photo
                            </button>
                            <input hidden type="file"
                                   #ImageCardFileInput
                                   (change)="onChangeFile($event, 'im_mediacard', 'card', null)"
                                   name="fileUpload" accept="image/*"/>
                        </div>
                    </div>

                    <hr>

                    <!-- Video Section -->
                    <div class="lg:flex items-center justify-center lg:space-x-8 lg:p-6">
                        <!-- Image Section -->
                        <div class="w-full lg:w-1/3">
                            <video #videoElement [src]="destination.im_mediavideo" preload="auto" muted
                                   *ngIf="isVideoUrl(destination.im_mediavideo)"
                                   controls class="rounded shadow">
                                <img ngSrc="./assets/images/other/broken-image-icon-19.png"
                                     alt="Fallback Image"
                                     height="512" width="512"
                                     class="mt-2 w-full h-full object-cover rounded-lg">
                                <p>Your browser doesn't support HTML5 video.</p>
                            </video>
                            <img [ngSrc]="'https://media.myaltituderewards.com/content/undefined'"
                                 *ngIf="!isVideoUrl(destination.im_mediavideo)"
                                 alt="Fallback Image"
                                 height="512" width="512"
                                 class="mt-2 w-full h-full object-cover rounded-lg">
                        </div>

                        <!-- Text and Upload Section -->
                        <div class="w-full lg:w-2/3 lg:text-left">
                            <h2 class="text-4xl font-semibold mb-2 text-primary-600">Main Video</h2>
                            <p class="mb-4">The main video is a dynamic and engaging feature that replaces the main
                                banner on your business profile when uploaded.
                                It creates a stronger visual impact and gives visitors an immersive experience of your
                                business, highlighting your brand, offerings, or unique atmosphere..
                            </p>
                            <p><strong class="text-indigo-400"
                                       (click)="moreInfoTooltip('','','/assets/images/help/banner-help.png')">Click here
                                to see its location</strong></p>
                            <h2 class="text-4xl font-semibold mb-2 text-primary-600">Guidelines for Uploading the
                                Video:</h2>
                            <p class="mb-4"><strong>Purpose:</strong> Choose a video that best showcases your business,
                                such as a tour of your space, a demonstration of your services, or a highlight of your
                                top products.</p>
                            <p class="mb-4"><strong>Quality:</strong> Ensure the video is high-resolution (at least 720p
                                or higher), properly cropped, and optimized for fast loading to deliver the best viewing
                                experience.</p>
                            <p class="mb-4"><strong>Relevance:</strong> Use a recent and compelling video that aligns
                                with your brand’s identity and current promotions or offerings.</p>
                            <p class="mb-4"><strong>Length:</strong> Keep the video concise—ideally 15 to 30 seconds—to
                                capture attention without overwhelming viewers.</p>
                            <p class="mb-4"><strong>Fallback:</strong> If no video is uploaded, the main banner image
                                will continue to display as a default.</p>
                            <p class="mb-4"><strong>Note:</strong> Once uploaded, the video will automatically replace
                                the main banner and become the first visual element visitors see on your profile.</p>

                            <button
                                *ngIf="!reviewMode &&  isVideoUrl(destination.im_mediavideo)"
                                (click)="deleteVideo()"
                                class="bg-red-500 text-white px-4 py-2 rounded hover:bg-yellow-600">
                                Delete
                            </button>
                            <button
                                *ngIf="!reviewMode"
                                (click)="MainVideoFileInput.click()"
                                class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 lg:my-float-right">
                                Change Video
                            </button>
                            <input hidden type="file"
                                   #MainVideoFileInput
                                   (change)="onChangeFile($event, 'im_mediavideo', 'video')"
                                   name="fileUpload" accept="video/*"/>
                        </div>
                    </div>
                </div>
            </ng-template>
        </mat-tab>

        <mat-tab label="Additional Info">
            <div class="pb-48 pr-4 flex flex-col flex-auto">
                <ng-container *ngFor="let story of destination.ar_stories; index as i;">

                    <div class="flex flex-col md:flex-row">
                        <div class="w-full lg:w-2/3">
                            <div>
                                <!-- Name -->
                                <mat-form-field class="w-full" floatLabel="always">
                                    <mat-label>{{ "Story Type: " + story.ds_label_storytype }}</mat-label>
                                    <input
                                        [readonly]="reviewMode"
                                        (input)="onInputMaxReach($event, 250)"
                                        maxlength="250"
                                        placeholder="Name"
                                        matInput
                                        (keyup)="onKeyupAutosave()"
                                        [(ngModel)]="story.ds_name">
                                </mat-form-field>

                                <div>
                                    <mat-label>Description:</mat-label>
                                    <quill-editor
                                        (onEditorCreated)="onEditorCreated($event)"
                                        [readOnly]="reviewMode"
                                        class="mt-2 w-full"
                                        (onContentChanged)="onKeyupAutosave()"
                                        [(ngModel)]="story.tx_description"
                                        [bounds]="'self'"
                                        [modules]="quillModules" [formats]="quillFormats"></quill-editor>
                                </div>
                            </div>
                        </div>

                        <div class="w-full md:flex md:w-1/3 md:pl-12 md:justify-center md:items-center">

                            <div class="relative">
                                <mat-label class="md:hidden">Image:</mat-label>
                                <img alt="No Image Available" [src]="story.im_media"
                                     default="./assets/images/other/broken-image-icon-19.png"
                                     class="object-cover rounded-lg h-64 w-full sm:w-82">
                                <button class="absolute bottom-0 right-0 mb-2 mr-2" mat-mini-fab
                                        *ngIf="!reviewMode"
                                        (click)="onFileInputClick(story.id_destinationstory)">
                                    <mat-icon svgIcon="heroicons_outline:magnifying-glass-plus"></mat-icon>
                                </button>
                            </div>

                            <!-- Fetch selected filed on change -->
                            <input hidden type="file"
                                   [id]="'FileUpload' + story.id_destinationstory"
                                   (change)="onChangeFile($event, 'ar_stories[' + i + '].im_media', 'story', story.id_destinationstory)"
                                   name="fileUpload" accept="image/*"/>

                        </div>
                    </div>

                    <hr>

                </ng-container>

                <div class="flex justify-center items-center pb-4 w-full"
                     *ngIf="!reviewMode">
                    <mat-form-field class="w-1/6 pr-4" [subscriptSizing]="'dynamic'">
                        <mat-select [(ngModel)]="selectedStoryID" [placeholder]="'Story Type'">
                            <mat-option
                                *ngFor="let stType of storyTypesArray; index as k;"
                                value="{{stType.id_storytype}}">{{ stType.ds_label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button
                        class="mt-1 w-1/12"
                        (click)="addProduct(destination.ar_stories, 1)"
                        [disabled]="!selectedStoryID"
                        mat-flat-button
                        [color]="'primary'">
                        <mat-icon svgIcon="feather:plus-circle"></mat-icon>
                        <span class="pl-4">Add Story</span>
                    </button>
                </div>

            </div>

        </mat-tab>

        <mat-tab label="Special Offers" *ngIf="destination.bl_offers && !reviewMode">
            <ng-template matTabContent>
                <special-offers-list
                    [fromProperty]="true"
                    [destination_ID]="destination.id_destination"></special-offers-list>
            </ng-template>
        </mat-tab>

        <mat-tab label="{{tab.ds_title_producttype}}" *ngFor="let tab of destination.ar_products; index as i">
            <div class="pb-48 pr-4 flex flex-col flex-auto">
                <ng-container *ngFor="let product of tab.ar_items; index as j;">

                    <div class="flex flex-col md:flex-row">
                        <div class="w-full lg:w-2/3">
                            <div>
                                <!-- Name -->
                                <mat-form-field class="w-[75%] lg:w-[85%] pr-2" floatLabel="always">
                                    <mat-label>Name</mat-label>
                                    <input
                                        [readonly]="reviewMode"
                                        (input)="onInputMaxReach($event, 250)"
                                        maxlength="250"
                                        (keyup)="onKeyupAutosave()"
                                        placeholder="Name"
                                        matInput
                                        [(ngModel)]="product.ds_name">
                                </mat-form-field>

                                <button
                                    *ngIf="!reviewMode"
                                    class="ml-auto sm:ml-0 inline-flex mt-6 min-h-12"
                                    style="float: right"
                                    mat-mini-fab
                                    (click)="removeProduct(tab, j)">
                                    <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                                </button>

                                <div>
                                    <mat-label>Description:</mat-label>
                                    <quill-editor
                                        (onEditorCreated)="onEditorCreated($event)"
                                        [readOnly]="reviewMode"
                                        (onContentChanged)="onKeyupAutosave()"
                                        class="mt-2 w-full"
                                        [(ngModel)]="product.tx_description"
                                        [bounds]="'self'"
                                        [modules]="quillModules" [formats]="quillFormats"></quill-editor>
                                </div>
                            </div>
                        </div>

                        <div class="w-full md:flex md:w-1/3 md:pl-12 md:justify-center md:items-center">

                            <div class="relative">
                                <mat-label class="md:hidden">Image:</mat-label>
                                <img alt="No Image Available" [src]="product.im_media"
                                     default="./assets/images/other/broken-image-icon-19.png"
                                     class="object-cover rounded-lg h-64 w-full sm:w-82">
                                <button class="absolute bottom-0 right-0 mb-2 mr-2" mat-mini-fab
                                        *ngIf="!reviewMode"
                                        (click)="onFileInputClick(product.id_destinationproduct)">
                                    <mat-icon svgIcon="heroicons_outline:magnifying-glass-plus"></mat-icon>
                                </button>
                            </div>

                            <!-- Fetch selected filed on change -->
                            <input hidden type="file"
                                   [id]="'FileUpload' + product.id_destinationproduct"
                                   (change)="onChangeFile($event, 'ar_products[' + i + '].ar_items[' + j + '].im_media', 'product', product.id_destinationproduct)"
                                   name="fileUpload"
                                   multiple="multiple" accept="image/*"/>

                        </div>
                    </div>

                    <hr>

                </ng-container>

                <button
                    *ngIf="!reviewMode"
                    class="text-center self-center"
                    mat-flat-button
                    [color]="'primary'"
                    (click)="addProduct(tab, 0)">
                    <mat-icon svgIcon="feather:plus-circle"></mat-icon>
                    <span class="pl-4">Add {{ tab.ds_title_producttype }}</span>
                </button>

            </div>
        </mat-tab> <!-- End of Product Tab -->


        <mat-tab label="Other Info" *ngIf="adminMode">
            <div class="pb-26 pr-4 max-h-fit">

                <!-- Name -->
                <mat-label class="flex"><p>Information</p>
                    <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-1"
                              matTooltip="This is your property's identifier in the admin portal. For support use this ID."></mat-icon>
                </mat-label>
                <mat-form-field class="w-full lg:w-2/3 pr-2">
                    <input
                        [readonly]="reviewMode"
                        (input)="onInputMaxReach($event, 250)"
                        maxlength="250"
                        matInput
                        (keyup)="onKeyupAutosave()"
                        [(ngModel)]="destination.ds_name">
                </mat-form-field>

            </div>
        </mat-tab>

    </mat-tab-group>

</div>
