import Quill from 'quill';

export const en_DestinationType = [
    {value: 1, view: "Home Page"},
    {value: 2, view: "Country"},
    {value: 3, view: "City"},
    {value: 4, view: "Hotel"},
    {value: 5, view: "Restaurant"},
    {value: 6, view: "Attraction"},
    {value: 7, view: "Shop"},
    {value: 8, view: "Other"},
    {value: 9, view: "Airline"},
    {value: 10, view: "Continent"},
    {value: 11, view: "FlyRegion"},
    {value: 12, view: "OuterRegion"},
    {value: 13, view: "State"},
    {value: 14, view: "InnerRegion"},
    {value: 15, view: "MiniDestination"},
    {value: 16, view: "Regions"},
    {value: 17, view: "Attractions"},
    {value: 18, view: "Destination"},

    {value: 30, view: "Climate"},

    {value: 100, view: "Gallery"},
    {value: 101, view: "Room Types"},
    {value: 102, view: "Rides"},
    {value: 103, view: "Products"},
    {value: 104, view: "Tours"},

    {value: 106, view: "Menu"},

    {value: 200, view: "HRAs"},
    {value: 299, view: "FeaturedChilds"},

    {value: 300, view: "All-Stories"},
    {value: 301, view: "Story-Info"},
    {value: 302, view: "Story-FlyInfo"},
    {value: 303, view: "Story-Place"},
    {value: 304, view: "Story-Advertisement"},
    {value: 305, view: "Story-Showcase"},
    {value: 306, view: "Story-Experience"},
    {value: 307, view: "Story-Activity"},
    {value: 308, view: "Story-Learning"},
    {value: 309, view: "Story-Map"},

    {value: 400, view: "Features (amenities, etc)"},
];

export const en_DestinationSuperType= [
    {value: 1, view: "SystemDestination"},
    {value: 2, view: "ClientDestination"},
    {value: 3, view: "PublicDestination"},
    {value: 4, view: "Information"},
    {value: 100, view: "SystemInternal"},
];

export const en_State= [
    {value: -1, view: "All"},
    {value: 0, view: "New"},
    {value: 1, view: "Editing"},
    {value: 2, view: "Draft"},
    {value: 3, view: "Approved"},
    {value: 4, view: "Production"},
    {value: 5, view: "Rejected"},
    {value: 6, view: "In Review"},
];


export const QuillConfiguration = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{'header': 1}, {'header': 2}],
        [{align: []}, {list: "ordered"}, {list: "bullet"}],
        [{'indent': '-1'}, {'indent': '+1'}],
        [{'size': ['small', false, 'large', 'huge']}],
        [{'header': [1, 2, 3, 4, 5, 6, false]}],
        [{'font': []}],
        [{'align': []}],
        ["clean"]
    ],
    clipboard: {
        matchers: [
            [
                Node.ELEMENT_NODE,
                (node, delta) => {
                    delta.ops = delta.ops.map((op) => {
                        // If there are attributes, remove color / background attributes
                        if (op.attributes) {
                            // Remove Quill's "color" or "background" attributes
                            delete op.attributes.color;
                            delete op.attributes.background;

                            // If there's a style string, strip out color/background-color
                            if (op.attributes.style) {
                                let styleStr = op.attributes.style as string;

                                // Remove `color: ...;`
                                styleStr = styleStr.replace(/color:[^;]+;?/gi, '');

                                // Remove `background-color: ...;`
                                styleStr = styleStr.replace(/background-color:[^;]+;?/gi, '');

                                // Cleanup: if nothing left in style, remove the style attribute entirely
                                styleStr = styleStr.trim();
                                if (styleStr === '') {
                                    delete op.attributes.style;
                                } else {
                                    op.attributes.style = styleStr;
                                }
                            }
                        }
                        return op;
                    });
                    return delta;
                },
            ],
        ],
    }
};

export const QuillFormats = ['link', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'header', 'indent', 'list', 'align', 'font', 'strike', 'script', 'direction'];

/**
 * Setup Quill clipboard matchers to remove colors and preserve blank lines.
 */
export function setupClipboardInterceptors(quill: Quill) {
    // const Delta = Quill.import('delta');
    //
    // // --- 1) Remove color / background for all element nodes ---
    // quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node: any, delta: any) => {
    //     delta.ops = delta.ops.map((op) => {
    //         // Remove Quill color / background attributes
    //         if (op.attributes) {
    //             delete op.attributes.color;
    //             delete op.attributes.background;
    //
    //             // Remove color / background-color from inline style
    //             if (op.attributes.style) {
    //                 let styleStr = op.attributes.style as string;
    //                 styleStr = styleStr.replace(/color:[^;]+;?/gi, '');
    //                 styleStr = styleStr.replace(/background-color:[^;]+;?/gi, '');
    //                 styleStr = styleStr.trim();
    //                 styleStr ? (op.attributes.style = styleStr) : delete op.attributes.style;
    //             }
    //         }
    //         return op;
    //     });
    //     return delta;
    // });
    //
    // // --- 2) Preserve blank lines by inserting a zero-width space ---
    // quill.clipboard.addMatcher(Node.TEXT_NODE, (node: any, delta: any) => {
    //     // Split the text into lines
    //     const originalText = node.data as string;
    //     let lines = originalText.split(/\r?\n/);
    //
    //     // For lines that are truly empty (or only whitespace), place an invisible ZWS
    //     lines = lines.map((line) => (line.trim().length === 0 ? '\u200B' : line));
    //
    //     // Rejoin with '\n' so we have the same number of lines
    //     const modifiedText = lines.join('\n');
    //
    //     // Return the updated Delta containing our new text
    //     return new Delta().insert(modifiedText);
    // });
}
